import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StudentLogin from "../../Components/StudentLogin"
import TeacherLogin from "../../Components/TeacherLogin";
import AdminLogin from "../../Components/AdminLogin";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Login = () => {
  const [activeForm, setActiveForm] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
        duration: 1200,
        once: false,
        mirror:true
    });
}, []);

  const handleButtonClick = (formType) => {
    setActiveForm(formType);
    navigate(`/login/${formType}`); // Update URL based on button click
  };

  return (
    <div>
      <h2 data-aos="flip-up" className="text-3xl font-bold font-serif text-[#444444] py-4">Login</h2>
      <div className="w-full"> 
      <div className="w-[85%] flex sm:flex-row flex-col justify-around gap-4 bg-gray-200 mx-auto px-8 py-16">
        <button
          className="border-y py-2 px-2 w-1/2 mx-auto text-[#444444] font-serif border-[#444444] rounded hover:text-[#fc700d] hover:scale-105 duration-300"
          onClick={() => handleButtonClick("student")}
        >
          Student Login
        </button>
        <button
          className="border-y py-2 px-2 w-1/2 mx-auto text-[#444444] font-serif border-[#444444] rounded hover:text-[#fc700d] hover:scale-105 duration-300"
          onClick={() => handleButtonClick("teacher")}
        >
          Teacher Login
        </button>
        <button
          className="border-y py-2 px-2 w-1/2 mx-auto text-[#444444] font-serif border-[#444444] rounded hover:text-[#fc700d] hover:scale-105 duration-300"
          onClick={() => handleButtonClick("admin")}
        >
          Admin Login
        </button>
      </div>
      </div>
      {activeForm === "student" && <StudentLogin />}     {/* {activeForm === "applicant" && <AplicentLogin />} */}
      {activeForm === "teacher" && <TeacherLogin />}
      {activeForm === "admin" && <AdminLogin />}
    </div>
  );
};

export default Login;
