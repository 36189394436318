import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import chancellor from "../assets/Chancellor.jpg";
import viceChancellor from "../assets/vicechancellor.jpg";
import principal from "../assets/pricipal.jpg";

const Section = () => {
  return (
    <>
      <section className="w-full bg-[#eff7ff]">
        <div className="p-4 flex flex-col md:flex-row gap-4">
          <div className="flex bg-white p-4 text-[#444444] w-full flex-wrap">
            <div className="flex  flex-col rounded border-y border-[#26d3f6] w-1/3">
              <img className="h-[180px] object-contain rounded-lg" src={chancellor} alt="Chancellor" />
              <span className="px-1 font-semibold">Shri Arif Mohammed Khan</span>
              <span className="px-1 text-xs">(Hon'ble Chancellor, Bihar)</span>
            </div>
            <div className="flex flex-col w-1/3 rounded border-y border-[#26d3f6]">
              <img className="h-[180px] object-contain rounded-lg" src={viceChancellor} alt="viceChancellor" />
              <span className="px-1 font-semibold">Prof. (Dr.) Jawahar Lal</span>
              <span className="px-1 text-xs">(Honourable Vice Chancellor)</span>
            </div>
            <div className="flex flex-col w-1/3 rounded border-y border-[#26d3f6]">
              <img className="h-[180px] object-contain rounded-lg" src={principal} alt="principal" />
              <span className="px-1 font-semibold">Prof. Arvind Kumar Sah</span>
              <span className="px-1 text-xs">(Principal, PBS College Banka)</span>
            </div>
          </div>
          <div className="flex items-start justify-around w-full md:w-3/4 ml-6 md:ml-0 flex-col">
           <div className="flex flex-row gap-2 h-1/4">
             <FontAwesomeIcon
              className="text-[#fc700d] mt-9"
              icon=
              "fa-solid fa-graduation-cap"
            />
            <span className="text-[#fc700d] mt-9 font-bold font-serif">About us</span>
           </div>
            <h1 className="h-1/2 font-semibold text-[#444444] text-[30px] md:text-[50px] w-[80%] font-serif">P.B.S. College, Banka</h1>
            <a href="/About" className="h-1/4 flex flex-row justify-center items-center">
            <button className="py-2 px-2 text-md hover:scale-105 duration-300 hover:bg-[#fc700d] rounded-lg font-serif text-white bg-[#26d3f6] font-medium">Read More</button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section;
