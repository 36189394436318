import Home from "../pages/Home";
import About from "../pages/About";
import Academics from "../pages/Academics";
import Admissions from "../pages/Admissions";
import Curriculum from "../pages/Curriculum";
import Gallery from "../Components/Gallery";
import Infrastructure from "../pages/Infrastructure";
import Careers from "../pages/Careers";
import Contact from "../pages/Contact";
import Login from "../pages/Login/Login";
import StudentLogin from "../Components/StudentLogin";
import TeacherLogin from "../Components/TeacherLogin";
import AdminLogin from "../Components/AdminLogin";
import AllNotice from "../Components/AllNotice";

export const mainRoutes = [
  { path: "/", element: <Home /> },
  { path: "/About", element: <About /> },
  { path: "/Admissions", element: <Admissions /> },
  { path: "/Academics", element: <Academics /> },
  { path: "/Curriculum", element: <Curriculum /> },
  { path: "/Gallery", element: <Gallery /> },
  { path: "/Infrastructure", element: <Infrastructure /> }, //Alumni
  { path: "/careers", element: <Careers /> },
  { path: "/Contact", element: <Contact /> },
  { path: "/login", element: <Login /> },
  { path: "/login/student", element: <StudentLogin /> },
  { path: "/login/teacher", element: <TeacherLogin /> },
  { path: "/login/admin", element: <AdminLogin /> },
  { path: "/all-notices", element: <AllNotice/> },
];
