import React , {useEffect} from "react";
// import image from "../assets/images.jpeg";
import bg_courses from "../assets/courses.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dummyFaculty from "../assets/Faculty.jpg"
import faculty from "../assets/slider3.jpeg"
import AOS from 'aos';
import 'aos/dist/aos.css';

const Academics = () => {

  useEffect(() => {
    AOS.init({
        duration: 1200,
        once: false,
        mirror:true
    });
}, []);

  return (
    <>
     {/* Programs Offered */}
     <div className="w-full">

      {/* Heading */}
      <div data-aos="flip-up" className="w-full flex justify-center">
        <div className="text-[#444444] py-4 font-bold sm:text-3xl text-xl font-serif" >Programs Offered</div>
      </div>

      {/* Container */}
      <div className="flex flex-row justify-around w-full px-4">
            {/* Image */}
            <div className="w-1/2 hidden sm:block">
              <img className="w-[90%] h-[90%] mx-auto shadow-[8px_8px_16px_-6px_rgba(0,0,0,0.4)]" data-aos="zoom-in" src={bg_courses} alt="courses" />
            </div>
            {/* Content */}
            <div className="md:w-1/2 w-full flex flex-col sm:gap-2 gap-0.5 justify-center bg-[#eaeaea]">
            <div className="flex flex-row sm:gap-1 gap-0 sm:ml-1 ml-0"> 
              <a href="/academics">
              <h3 className="font-bold font-serif text-sm sm:text-lg  text-[#444444]">Programs Offered</h3>
              </a>
              <FontAwesomeIcon
                className="text-[#444444] mt-1 animate-bounce w-6 h-6"
                icon="fa-solid fa-book"
              />
            </div>
              <ul className="list-disc bg-gray-200 font-ubuntu text-[#444444]">
                <li className="font-semibold hover:translate-x-1 hover:text-[#7c86ee] ml-6 duration-300">
                  Science
                  <ol className="font-normal">
                    <li>B.Sc</li>
                    <li>M.Sc</li>
                  </ol>
                </li>
                <li className="font-semibold hover:translate-x-1 hover:text-[#7c86ee] ml-6 duration-300">
                  Arts
                  <ol className="font-normal"> 
                    <li>B.A</li>
                    <li>M.A</li>
                  </ol>
                  </li>
                <li className="font-semibold hover:translate-x-1 hover:text-[#7c86ee] ml-6 duration-300">
                  Commerce
                  <ol className="font-normal">
                    <li>B.Com</li>
                    <li>M.Com</li>
                  </ol></li>
              </ul>
            </div>
          </div>
     </div>

     {/* Faculty Members */}
     <div className="w-full">

      {/* Heading */}
      <div  data-aos="flip-up" className="w-full flex flex-row gap-3 justify-center">
        <div className="text-[#444444] py-4 font-bold sm:text-3xl text-xl font-serif" >Faculty Members</div>
        <FontAwesomeIcon icon="fa-solid fa-people-group" className="sm:text-3xl text-xl pt-4 text-[#444444]"/>
      </div>

      {/* Container */}
     <div className="w-full flex flex-row justify-around gap-3">
        <div className="md:w-1/2 w-3/4 p-4">
         <img src={faculty} alt="faculty" className="md:w-3/4 w-full shadow-[0px_0px_16px_4px_rgba(0,0,0,0.4)]"/>
         <div className="p-2 flex mt-2 flex-col justify-end sm:mx-8 mx-2 md:items-end items-center text-[#444444] bg-[#edf2fe] font-sans sm:font-bold font-medium">
            <div>Prof. Arvind Kumar Sah</div>
            <div>(Principal)</div>
            <div>PBS College Banka</div>
         </div>
        </div>
        <div className="w-1/2 p-4 mt-16 hidden md:block">
          <img src={dummyFaculty} alt="dummy" className="shadow-[0px_0px_16px_4px_rgba(0,0,0,0.4)]"/>
        </div>
     </div>
     </div>

    </>
  );
};

export default Academics;
