import React from "react";
import Slider from "../Components/Slider.jsx";
import Section from "../Components/Section.jsx";
import Notice from "../Components/Notice.jsx"
import About from "../Components/About.jsx";
import Ourteam from "../Components/Ourteam.jsx";
import Calender from "../Components/Calender.jsx";
// import Award from "../Components/Award.jsx";
// import Event from "../Components/Event.jsx";
// import FeatureCourse from "../Components/FeatureCourse.jsx";
// import RecentActivity from "../Components/RecentActivity.jsx";
// import VideoTour from "../Components/VideoTour.jsx";
// import OnlineClass from "../Components/OnlineClass.jsx";
// import Testiminol from "../Components/Testiminol.jsx";
// import Gallery from "../Components/Gallery.jsx";
// import Hero from "../Components/Hero.jsx";

const Home = () => {
  return (
    <>
      <Slider /> 
      {/* Faculty section */}
      <Section />
      {/* Notice board */}
       <Notice />
      {/* About college (Theory) */}
      <About />
      {/* Infrastructure */}
      <Ourteam />
      {/* Courses */}
      <Calender />

      {/* 
      <Award /> don't add
      <Hero /> don't add
      <Event /> don't add
      <FeatureCourse /> don't add
      <RecentActivity /> don't add
      <VideoTour /> don't add
      <OnlineClass /> don't add
      <Gallery /> don't add
       <Testiminol /> don't add
       */} 

    </>
  );
};

export default Home;
