import React from 'react'
import { DataNotice } from "./DataNotice";
import { Link } from "react-router-dom";

const Notice = () => {

  const visibleNotices = DataNotice.slice(0,4);

  return (
    <div className='pt-2 pb-4'>
        <div>
      <div>
        <h2 className="text-[#444444] font-serif font-bold text-2xl py-2">Notice Board</h2>
      </div>
      {/* Container */}
      <div className='flex flex-col bg-[#eff7ff] sm:p-4 p-2 sm:mx-16 mx-8'>
        {/* Lists */}
        <div className='overflow-hidden ml-4'>
        <ul className='list-disc text-[#444444] animate-vertical-scroll hover:pause-animation'>
        {visibleNotices.map((notice)=> (
          <li 
          key={notice.id}
          >
            <div className='mb-0 text-xs'>{notice.date}</div>
            <span className='text-[#36348e] font-serif font-semibold hover:text-[#fc700d]'> 
              <a href={notice.pdfUrl} target="_blank" rel="noopener noreferrer">{notice.title}</a>
            </span>
          </li>
        ))}
      </ul>
        </div>
        {/* Button */}
      <div className="flex justify-end mt-4 items-end">
        <Link to="/all-notices">
        <button className='flex flex-row font-serif hover:bg-[#fc700d] hover:scale-105 duration-300 bg-[#26d3f6] text-white rounded px-2 py-2'>
         Read More
         <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2 mt-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
         <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </button>
        </Link>
      </div>
      </div>
    </div>
    </div> 
  )
}

export default Notice