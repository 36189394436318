import React, { useEffect } from "react";
import hackathon from "../assets/hackathon.png"
import artExhibition from "../assets/ArtExhibition.jpg"
import scienceFair from "../assets/ScienceFair.jpg"
import upcoming from "../assets/upcoming_events.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Curriculum = () => {

  useEffect(() => {
    AOS.init({
        duration: 1200,
        once: false,
        mirror:true
    });
}, []);

  return (
    <>
    {/* heading */}
    <div>
      <h2 className="text-[#444444] text-3xl font-bold font-serif p-4">College Activities</h2>
    </div>

      {/* Upcoming Events */}
      <div className="w-full">
      <div className="md:w-[70%] w-[90%] translate-x-[5%] bg-[#fffeb1] relative">
        {/* Content */}
        <div className="flex flex-col md:w-[50%] w-[90%] md:translate-x-[10%] translate-x-0 md:translate-y-1/4 translate-y-0 gap-4">
         <div className="font-bold sm:text-3xl text-xl text-[#444444] font-serif">Upcoming Events</div>
         <ul className="ml-8 text-[#444444] font-sans sm:font-bold font-medium">
          <li className="p-2 bg-white">Annual Sports Day - January 15</li>
          <li className="p-2 ">Cultural Fest - February 5</li>
          <li className="p-2 bg-white">Tech Symposium - March 20</li>
          <li className="p-2">Convocation - April 10</li>
         </ul>
        </div>
        {/* Image */}
        <div className="translate-x-[80%] -translate-y-[45%] w-3/4 hidden md:block">
          <img src={upcoming} alt="upcoming events" data-aos="fade-right"/>
        </div>
      </div>
      </div>
     {/* Recent Activities */}
     <div>
       <div>
         <h2 className="text-[#444444] text-3xl font-bold font-serif p-4">Recent Activities</h2>
       </div>
       <div className="w-full"> 
         <div className="flex md:flex-row flex-col items-center justify-around gap-4 px-4 w-full">
          <div className="border-b rounded border-[#444444] h-full md:w-1/3 w-3/4">
            {/* Image */}
            <div className="h-[65%]">
              <img src={hackathon} alt="Hackathon" className="w-full object-cover h-full"/>
            </div>
            {/* Content */}
            <div className="p-3 h-[35%]">
              <h2 className="text-xl font-bold text-[#444444] font-serif">Hackathon 2024</h2>
              <p className="text-sm text-[#444444]">Students showcased their coding skills in a 24-hour hackathon.</p>
            </div>
          </div>
          <div className="border-b rounded border-[#444444] h-full md:w-1/3 w-3/4">
             {/* Image */}
            <div className="h-[65%]">
              <img src={artExhibition} alt="Art" className="w-full object-cover h-full"/>
            </div>
            {/* Content */}
            <div className="p-3 h-[35%]">
              <h2 className="text-xl font-bold text-[#444444] font-serif">Art Exhibition</h2>
              <p className="text-sm text-[#444444]">Students displayed their artistic talents at the annual art exhibition.</p>
            </div>
          </div>
          <div className="border-b rounded border-[#444444] h-full md:w-1/3 w-3/4">
             {/* Image */}
            <div className="h-[65%]">
             <img src={scienceFair} alt="Science" className="w-full object-cover h-full"/>
            </div>
            {/* Content */}
            <div className="p-3 h-[35%]">
              <h2 className="text-xl font-bold text-[#444444] font-serif">Science Fair</h2>
              <p className="text-sm text-[#444444]">Innovative projects were presented by students during the science fair.</p>
            </div>
          </div>
         </div>
       </div>
     </div>
    </>
  );
};

export default Curriculum;
