/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  FaSearch,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaEye,
  FaTimes,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Component() {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "name", direction: "asc" });
  const [selectedStudent, setSelectedStudent] = useState(null);

  // Fetch students from backend
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch("https://backend-pbs.onrender.com/students/all");
        const data = await response.json();
        setStudents(data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, []);

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const filteredStudents = [...students]
    .filter((student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      }
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    });

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) return <FaSort className="text-gray-300" />;
    return sortConfig.direction === "asc" ? (
      <FaSortUp className="text-purple-500" />
    ) : (
      <FaSortDown className="text-purple-500" />
    );
  };

  const StudentProfileModal = ({ student, closeModal }) => (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-40 p-4">
      <div className="bg-white rounded-xl shadow-lg w-full max-w-md">
        <div className="p-6 border-b flex justify-between items-center">
          <h2 className="text-xl font-semibold">{student.name}'s Profile</h2>
          <button onClick={closeModal} className="text-gray-500">
            <FaTimes />
          </button>
        </div>
        <div className="p-6 space-y-4 text-gray-700">
          <p><strong>Email:</strong> {student.email}</p>
          <p><strong>Phone:</strong> {student.mobileNo}</p>
          <p><strong>Class:</strong> {student.class}</p>
          <p><strong>Father's Name:</strong> {student.fatherName}</p>
          <p><strong>Mother's Name:</strong> {student.motherName}</p>
          <p><strong>Gender:</strong> {student.gender}</p>
          <p><strong>Blood Group:</strong> {student.bloodGroup}</p>
          <p><strong>Roll No:</strong> {student.rollNo}</p>
          <p><strong>Course Taken:</strong> {student.courseTaken}</p>
          <p><strong>Branch Name:</strong> {student.branchName}</p>
          <p><strong>Admission Year:</strong> {student.admissionYear}</p>
          <p>
            <strong>Address:</strong> {student.address}, {student.cityVillage},{" "}
            {student.state}, {student.country}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Students</h1>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border px-4 py-2 rounded-md"
          />
          <button
            onClick={() => navigate("/addStudent")}
            className="bg-purple-600 text-white px-4 py-2 rounded-md"
          >
            Add Student
          </button>
        </div>

        <div className="bg-white rounded-lg shadow overflow-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-purple-600 text-white" >
              <tr>
                <th onClick={() => handleSort("name")} className="cursor-pointer px-4 py-2">
                  Name <SortIcon column="name" />
                </th>
                <th>Father's Name</th>
                <th>Class</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Enrollment No</th>
                <th>Roll No</th>
                <th>Course</th>
                <th>Branch</th>
                <th>Sesion Year</th>
                <th>Adhar Card No</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <tr key={student.id} className="hover:bg-gray-50">
                  <td>{student.name}</td>
                  <td>{student.fatherName}</td>
                  <td>10</td> {/* <td>{student.class}</td> */}
                  <td>{student.email}</td>
                  <td>{student.mobileNo}</td>
                 <td>0127C201027</td>  {/* <td>{student.enrollmentNo}</td> */}
                  <td>{student.rollNo}</td>
                  <td>{student.courseTaken}</td>
                  <td>{student.branchName}</td>
                  <td>{student.admissionYear}</td>
                  <td>124545334335</td>  {/* {student.addarCardNo} */}
                  <td>
                    <button
                      onClick={() => setSelectedStudent(student)}
                      className="text-purple-600"
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {selectedStudent && (
          <StudentProfileModal
            student={selectedStudent}
            closeModal={() => setSelectedStudent(null)}
          />
        )}
      </div>
    </div>
  );
}
