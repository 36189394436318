import React from "react";
import web_school from "../assets/lps_web_school_campus.png";
import web_library from "../assets/lps_web_library.png";
import web_smart_class from "../assets/lps_web_smart_class.png";
import web_infirmary from "../assets/lps_web_infirmary.png";

const Ourteam = () => {
  return (
    <>
      <section className="our-team pt-2 bg-gradient-to-r from-blue-100 to-cyan-100">
        <div className="container">
          <div>
            <h2 className="font-bold text-2xl font-serif text-[#444444]">Infrastructure - Join us today to experience the best</h2>
          </div >
          <div>
            <div className="row">
              <article className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 item">
                <figure>
                    <img src={web_school} alt="SCHOOL CAMPUS" />
                </figure>
                <div className="info">
                  <h3 className="font-serif font-semibold text-lg text-[#444444] text-center">
                    SCHOOL CAMPUS
                  </h3>
                  <p className="font-sans text-sm py-3" style={{ textAlign: "justify" }}>
                    The central monitoring system ensures total safety &amp;
                    complete security of the school building and for all the
                    students (CCTVs).The form and the colours of the building
                    have been carefully selected to provide mental stimulation
                    to the children.{" "}
                  </p>
                </div>
              </article>
              <article className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 item">
                <figure>
                    <img src={web_library} alt="LIBRARY" />
                </figure>
                <div className="info">
                  <h3 className="font-serif font-semibold text-lg text-[#444444] text-center">
                    LIBRARY
                  </h3>
                  <p className="font-sans text-sm py-4" style={{ textAlign: "justify" }}>
                    Books are a gateway to a world of limitless opportunity. The
                    school boasts of a well-stocked library with books for every
                    age group. There is a reference section and a Teacher's
                    Corner as well.{" "}
                  </p>
                </div>
              </article>
              <article className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 item">
                <figure>
                    <img src={web_smart_class} alt="SMART CLASSROOMS" />
                </figure>
                <div className="info">
                  <h3 className="font-serif font-semibold text-lg text-[#444444] text-center">
                   SMART CLASSROOMS
                  </h3>
                  <p className="font-sans text-sm py-4" style={{ textAlign: "justify" }}>
                    PBS Collage is fitted with smart boards in all the
                    classNamerooms. The use of technology motivates the learners
                    by giving them an opportunity to enjoy interactive learning.
                    The subjects also come alive.{" "}
                  </p>
                </div>
              </article>
              <article className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 item">
                <figure>
                    <img src={web_infirmary} alt="INFIRMARY" />
                </figure>
                <div className="info">
                  <h3 className="font-serif font-semibold text-lg text-[#444444] text-center">
                  INFIRMARY
                  </h3>
                  <p className="font-sans text-sm py-4" style={{ textAlign: "justify" }}>
                    Our infirmary is staffed by a full time nurse and a visiting
                    doctor who monitor the general physical development of our
                    children and provide immediate first-aid treatment as
                    needed.{" "}
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ourteam;
