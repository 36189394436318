import React, { useState, useEffect } from "react";
import new_logo from "../assets/schoollogo.jpg";
import { NavLink } from "react-router-dom";
import { useBreakpointValue } from "@chakra-ui/react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prev) => !prev);

  // State to track the scroll position
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Responsive styles for navbar background and text
  const navbarBg = useBreakpointValue({ base: "gray.800", md: "white" });
  // const navbarTextColor = useBreakpointValue({ base: "white", md: "black" });

  return (
    <>
      {/* Header Section */}
      <div className="bg-[#fc700d] text-white px-4 md:px-12 py-2 md:py-4">
        <div className="h-auto md:h-16 flex items-center justify-between flex-row gap-2">
          <div className="flex flex-wrap justify-center gap-4">
            <a href="tel:8860464098" target="_blank" rel="noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-phone hover:scale-110 ease-in-out duration-100"
              >
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
              </svg>
            </a>
            <a
              href="mailto:pbscollegebanka.1959@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="w-7 hover:scale-110 ease-in-out duration-100 hover:text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                />
              </svg>
            </a>
          </div>
          <a
            href="/Admissions"
            className="bg-[#fffeb1] hover:bg-opacity-80 text-[#444444] font-sans font-medium 
            px-2 py-2 md:px-6 md:py-3 rounded shadow text-sm md:text-base"
          >
            Apply for Admissions
          </a>
        </div>
      </div>

      {/* Navbar Section */}
      <div
        className={`bg-${navbarBg} px-4 md:px-12 shadow-sm ${
          isScrolled ? "fixed top-0 left-0 right-0" : "relative"
        } z-20 transition-[top] duration-300 ease-in-out`}
      >
        <div className="flex h-auto md:h-20 items-center justify-between flex-wrap flex-row">
          {/* Clickable Logo */}
          <a href="/" className="hover:no-underline relative inline-block">
            <div className="w-[80px] md:w-[100px] h-[80px] md:h-[100px] rounded-full relative overflow-hidden">
              <img
                src={new_logo}
                alt="Logo"
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-black flex items-center justify-center opacity-5 hover:opacity-50">
              <span className="font-semibold font-serif text-white">Home</span>
              </div>
            </div>
          </a>
          {/* Mobile Menu Button */}
          <button
            class="md:hidden p-2 text-white bg-transparent focus:outline-none"
            aria-label="Toggle Menu"
            onClick={toggleMenu}
          >
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#fc700d"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#fc700d"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>

          <nav className="hidden md:flex space-x-6">
            <NavLink
              to="/About"
              className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }
            >
               About
            </NavLink>
            <NavLink to="/Curriculum" className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }>
              Curriculum
            </NavLink>
            <NavLink to="/Gallery" className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }>
              Gallery
            </NavLink>
            <NavLink to="/Admissions" className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }>
              Admissions
            </NavLink>
            <NavLink to="/Contact" className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }>
              Contact
            </NavLink>
            <NavLink to="/Academics" className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }>
              Academics
            </NavLink>
            <NavLink to="/Infrastructure" className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }>
              Alumni
            </NavLink>
            <NavLink to="/Login" className={({ isActive }) =>
              isActive
               ? "text-[#fc700d] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
               : "text-[#444444] hover:text-[#fc700d] hover:scale-105 font-sans font-semibold"
             }>
              Login
            </NavLink>
          </nav>
        </div>
        {/* Mobile Dropdown */}
        {isOpen && (
          <div className="md:hidden pb-2 bg-[#26d3f6] rounded-lg px-32 w-1/4 absolute right-2">
            <nav className="flex flex-col font-serif items-center space-y-4 pt-3 text-center">
              <NavLink to="/About" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                About
              </NavLink>
              <NavLink to="/Curriculum" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                Curriculum
              </NavLink>
              <NavLink to="/Gallery" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                Gallery
              </NavLink>
              <NavLink to="/Admissions" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                Admissions
              </NavLink>
              <NavLink to="/Contact" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                Contact
              </NavLink>
              <NavLink to="/Academics" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                Academics
              </NavLink>
              <NavLink to="/Infrastructure" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                Alumni
              </NavLink>
              <NavLink to="/Login" className={({ isActive }) => isActive ? "rounded-full py-1 px-1 font-bold hover:bg-[#fc700d] hover:text-white  text-[#fc700d]" : "text-white rounded-full py-1 px-1 hover:bg-[#fc700d]"}>
                Login
              </NavLink>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
