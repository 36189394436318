import React, { useState, useEffect } from "react";
import Select from "react-select";
import { State, City } from "country-state-city";
import "./AdmissionForm.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const AdmissionForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    motherName: "",
    fatherName: "",
    address: "",
    gender: "",
    dob: "",
    course: "",
    email: "",
  });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Fetch only the states of India (ISO code: IN)
    const statesList = State.getStatesOfCountry("IN");
    setStates(statesList);
  }, []);

  useEffect(() => {
    AOS.init({
        duration: 1200,
        once: false,
        mirror:true
    });
}, []);

  useEffect(() => {
    if (formData.state) {
      // Fetch cities of the selected state in India
      const citiesList = City.getCitiesOfState("IN", formData.state);
      setCities(citiesList);
      setFormData((prev) => ({ ...prev, city: "" })); // Reset city when state changes
    }
  }, [formData.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData((prev) => ({ ...prev, [field]: selectedOption? selectedOption.value : "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    alert("Form submitted successfully!");
  };

  const handleReset = (e) => {
    e.preventDefault(); 
    setFormData({
      firstName: "",
      lastName: "",
      motherName: "",
      fatherName: "",
      address: "",
      gender: "",
      dob: "",
      course: "",
      email: "",
    });
    setCities([]);
  };

  return (
    <>
      <div>
        <div data-aos="zoom-in" className="md:text-3xl text-xl text-white font-bold font-ubuntu text-center bg-[#fc700d] py-4">
          PBS College Admission 2024-2025
        </div>

        <div className="w-full mt-4">
          <div className="w-[80%] mx-auto bg-[#dae6f2] rounded p-4">
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              {/* First name */}
              <div>
                <label htmlFor="firstName">First Name:</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="!border-none"
                  required
                />
              </div>

              {/* Last name */}
              <div>
                <label htmlFor="lastName">Last Name:</label>
                <input
                  type="text"
                  id="lastName"
                  className="!border-none"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Mother's name */}
              <div>
                <label htmlFor="motherName">Mother's Name:</label>
                <input
                  type="text"
                  id="motherName"
                  className="!border-none"
                  name="motherName"
                  value={formData.motherName}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Father's Name */}
              <div>
                <label htmlFor="fatherName">Father's Name:</label>
                <input
                  type="text"
                  id="fatherName"
                  className="!border-none"
                  name="fatherName"
                  value={formData.fatherName}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Address */}
              <div>
                <label htmlFor="address">Address:</label>
                <textarea
                  id="address"
                  name="address"
                  className="!border-none w-full rounded"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Gender */}
              <div>
                <label htmlFor="gender">Gender:</label>
                <select
                  id="gender"
                  className="!border-none w-full p-2"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {/* State Dropdown */}
              <div>
                <label htmlFor="state">State:</label>
                <Select
                  id="state"
                  options={states.map((state) => ({
                    value: state.isoCode,
                    label: state.name,
                  }))}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, "state")
                  }
                   className="!border-none"
                  value={
                    formData.state ? { value: formData.state, label: states.find((s) => s.isoCode === formData.state)?.name } : null 
                  }
                  placeholder="Select a state"
                  isClearable
                  required
                />
              </div>

              {/* City Dropdown */}
              <div>
                <label htmlFor="city">City:</label>
                <Select
                  id="city"
                  options={cities.map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))}
                  onChange={(selectedOption) =>
                    handleSelectChange(selectedOption, "city")
                  }
                  value={
                    formData.city
    ? { value: formData.city, label: formData.city }
    : null
                  }
                  placeholder="Select a city"
                  isClearable
                  className="!border-none"
                  required
                  isDisabled={!formData.state}
                />
              </div>

              {/* DOB */}
              <div>
                <label htmlFor="dob">Date of Birth:</label>
                <input
                  type="date"
                  id="dob"
                  className="!border-none w-full p-2 rounded"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Course */}
              <div>
                <label htmlFor="course">Course:</label>
                <select
                  id="course"
                  name="course"
                  className="!border-none w-full p-2"
                  value={formData.course}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Course
                  </option>
                  <option value="bsc">B.Sc</option>
                  <option value="ba">B.A</option>
                  <option value="bcom">B.Com</option>
                </select>
              </div>

              {/* Email */}
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  className="!border-none w-full p-2 rounded"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="flex flex-col justify-around gap-2 w-full mt-4 p-2 text-white">
                <div className="text-center">
                  <button type="submit" className="bg-[#26d3f6] !w-[80px] rounded md:py-2 py-1 md:text-lg text-sm md:font-bold font-medium hover:scale-105 duration-300">Submit</button>
                </div>
                <div>
                  <button type="reset" className="bg-[#26d3f6] rounded py-1 px-3 hover:scale-105 duration-300 ">Reset</button>
                </div>  
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdmissionForm;
