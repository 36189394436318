import React, { useState } from "react";
import "./StudentRegistrationForm.css"; // Importing CSS for styling
import { useNavigate } from "react-router-dom";

const StudentRegistrationForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    fatherName: "",
    class: "",
    section: "",
    email: "",
    mobileNo: "",
    rollNo: "",
    enrollmentNo: "",
    courseTaken: "",
    branchName: "",
    admissionYear: "",
    adharCardNo: "",
    status: "",
    state: "",
    country: "",
    address: "",
    document: null,
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data for API submission
    const submissionData = new FormData();
    for (const key in formData) {
      submissionData.append(key, formData[key]);
    }

    try {
      const response = await fetch("https://backend-pbs.onrender.com/students/register", {
        method: "POST",
        body: submissionData,
      });

      if (response.ok) {
        alert("Student added successfully!");
        navigate("/studentList");
      } else {
        alert("Failed to add student. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="student-registration-form">
      <h2 className="form-title">Student Registration Form</h2>
      <form className="form-container" onSubmit={handleSubmit}>
        {/* Name */}
        <div className="form-group">
          <label className="form-label">Name:</label>
          <input
            className="form-input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        {/* Father's Name */}
        <div className="form-group">
          <label className="form-label">Father's Name:</label>
          <input
            className="form-input"
            type="text"
            name="fatherName"
            value={formData.fatherName}
            onChange={handleChange}
            required
          />
        </div>

        {/* Class */}
        <div className="form-group">
          <label className="form-label">Class:</label>
          <input
            className="form-input"
            type="text"
            name="class"
            value={formData.class}
            onChange={handleChange}
            required
          />
        </div>

        {/* Section */}
        <div className="form-group">
          <label className="form-label">Section:</label>
          <input
            className="form-input"
            type="text"
            name="section"
            value={formData.section}
            onChange={handleChange}
            required
          />
        </div>

        {/* Email */}
        <div className="form-group">
          <label className="form-label">Email:</label>
          <input
            className="form-input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        {/* Contact Number */}
        <div className="form-group">
          <label className="form-label">Contact Number:</label>
          <input
            className="form-input"
            type="tel"
            name="mobileNo"
            value={formData.mobileNo}
            onChange={handleChange}
            required
          />
        </div>

        {/* Roll Number */}
        <div className="form-group">
          <label className="form-label">Roll Number:</label>
          <input
            className="form-input"
            type="text"
            name="rollNo"
            value={formData.rollNo}
            onChange={handleChange}
            required
          />
        </div>

        {/* Enrollment Number */}
        <div className="form-group">
          <label className="form-label">Enrollment Number:</label>
          <input
            className="form-input"
            type="text"
            name="enrollmentNo"
            value={formData.enrollmentNo}
            onChange={handleChange}
            required
          />
        </div>

        {/* Course */}
        <div className="form-group">
          <label className="form-label">Course Taken:</label>
          <input
            className="form-input"
            type="text"
            name="courseTaken"
            value={formData.courseTaken}
            onChange={handleChange}
            required
          />
        </div>

        {/* Branch */}
        <div className="form-group">
          <label className="form-label">Branch Name:</label>
          <input
            className="form-input"
            type="text"
            name="branchName"
            value={formData.branchName}
            onChange={handleChange}
            required
          />
        </div>

        {/* Admission Year */}
        <div className="form-group">
          <label className="form-label">Admission Year:</label>
          <input
            className="form-input"
            type="number"
            name="admissionYear"
            value={formData.admissionYear}
            onChange={handleChange}
            required
          />
        </div>

        {/* Aadhar Card */}
        <div className="form-group">
          <label className="form-label">Aadhar Card Number:</label>
          <input
            className="form-input"
            type="text"
            name="adharCardNo"
            value={formData.adharCardNo}
            onChange={handleChange}
            required
          />
        </div>

        {/* Document */}
        <div className="form-group">
          <label className="form-label">Document:</label>
          <input
            className="form-input"
            type="file"
            name="document"
            accept="application/pdf,application/msword"
            onChange={handleFileChange}
            required
          />
        </div>

        {/* Status */}
        <div className="form-group">
          <label className="form-label">Status:</label>
          <select
            className="form-select"
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="">Select Status</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>

        <button className="form-submit" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default StudentRegistrationForm;
