import React, { useState } from "react";
import campus from "../assets/campus_tour.mp4";
import ReactPlayer from "react-player";
import img1 from "../assets/home1.jpg";
import img2 from "../assets/home2.jpg";
import img3 from "../assets/home3.jpg";
import img4 from "../assets/slider4.jpg";
import img5 from "../assets/slider2.png";
import img6 from "../assets/slider1.jpg";
import img7 from "../assets/Campus.jpeg";
import seminar0 from "../assets/Seminar_brochure.jpeg"
import seminar1 from "../assets/Seminar1.jpeg"
import seminar2 from "../assets/Seminar2.jpeg"
import seminar3 from "../assets/Seminar3.jpeg"
import seminar4 from "../assets/Seminar4.jpeg"
import seminar5 from "../assets/Seminar5.jpeg"
import seminar6 from "../assets/Seminar6.jpeg"

const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage("");
  };

  const handleBackdropClick = (e) => {
    // Close the modal only if the click is outside the modal content
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <>
      {/* Heading */}
      <div className="bg-[#fc700d] py-4">
        <div className="text-5xl text-white font-bold font-serif text-center">
          Gallery
        </div>
      </div>

      <div className="flex flex-col items-center gap-4 w-full">

        {/* Images */}
        <div className="w-[90%] pt-2">
          <div className="flex flex-wrap md:gap-3 gap-1 w-full">

            {/* International Seminar 2024 */}
            <div className="py-2">
            <div className="py-2 bg-gray-200 sm:text-2xl text-xl pl-2 font-semibold text-[#444444]">International Seminar on Mathematics 2024</div>
            <div className="flex flex-wrap justify-around py-1">
            <img
              src={seminar0} 
              className="w-1/4 p-1"
              alt="seminar0"
              onClick={() => handleImageClick(seminar0)}
            />
            <img
              src={seminar1} 
              className="w-1/4 p-1"
              alt="seminar1"
              onClick={() => handleImageClick(seminar1)}
            />
            <img
              src={seminar2} 
              className="w-1/4 p-1"
              alt="seminar2"
              onClick={() => handleImageClick(seminar2)}
            />
            <img
              src={seminar3} 
              className="w-1/4 p-1"
              alt="seminar3"
              onClick={() => handleImageClick(seminar3)}
            />
            <img
              src={seminar4} 
              className="w-1/4 p-1"
              alt="seminar4"
              onClick={() => handleImageClick(seminar4)}
            />
            <img
              src={seminar5} 
              className="w-1/4 p-1"
              alt="seminar5"
              onClick={() => handleImageClick(seminar5)}
            />
            <img
              src={seminar6} 
              className="w-1/4 p-1"
              alt="seminar6"
              onClick={() => handleImageClick(seminar6)}
            />
            </div>
            </div>

             {/* Sports Day */}
            <div className="py-2"> 
              <div className="py-2 bg-gray-200 sm:text-2xl text-xl pl-2 font-semibold text-[#444444]">Sports Day</div>
              <div className="flex flex-wrap justify-around py-1">
              <img
              src={img1} 
              className="w-1/4 p-1"
              alt="gallery1"
              onClick={() => handleImageClick(img1)}
            />
              <img
              src={img4}
              className="w-1/4 p-1"
              alt="gallery4"
              onClick={() => handleImageClick(img4)}
            />
            <img
              src={img5}
              alt="gallery5"
              className="w-1/4 p-1"
              onClick={() => handleImageClick(img5)}
            />
              </div>
            </div>

            {/* Campus Life */}
            <div className="py-2">
            <div className="py-2 bg-gray-200 sm:text-2xl text-xl pl-2 font-semibold text-[#444444]">Campus Life</div>
            <div className="flex flex-wrap justify-around py-1">
            <img
              src={img7}
              alt="gallery1"
              className="w-1/4 p-1"
              onClick={() => handleImageClick(img7)}
            />
            <img
              src={img2}
              alt="gallery2"
              className="w-1/4 p-1"
              onClick={() => handleImageClick(img2)}
            />
            <img
              src={img3}
              alt="gallery3"
              className="w-1/4 p-1"
              onClick={() => handleImageClick(img3)}
            />
          
            <img
              src={img6}
              alt="gallery6"
              className="w-1/4 p-1"
              onClick={() => handleImageClick(img6)}
            />
            </div>
            </div>
            
          </div>

          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex justify-center items-center w-1/2 mx-auto z-50" onClick={handleBackdropClick}>
              <div className="relative">
                <div className="bg-white text-[#fc700d] border-y border-[#fc700d] font-serif md:font-bold font-medium pl-3 md:text-xl text-sm py-2">PBS Photo Gallery</div>
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="max-w-full max-h-screen"
                />
                <button
                  className="absolute top-1 right-2 text-[#fc700d] md:text-3xl text-xl md:font-bold font-medium"
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
              </div>
            </div>
          )}
        </div>

         {/* Campus Tour */}
         <div className="w-[90%]">
          <div className="sm:text-2xl text-xl text-[#444444] font-semibold bg-gray-200 py-2 mb-2 pl-2">
            Campus Tour
          </div>
          <div>
            <ReactPlayer
              url={campus}
              className="react-player"
              playing={false}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>

      </div>
    </>
  );
};

export default Gallery;
