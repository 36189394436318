import React  from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css'; 
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import alumni_img from "../assets/alumni_img.jpg";
import alumni_img2 from "../assets/alumni_img_2.webp"
import alumni_img3 from "../assets/alumni_img3.jpg"

const AlumniPage = () => {

  const settings = {
    modules: [Navigation, Pagination, Autoplay],
    spaceBetween: 30,
    loop:true,
    speed: 500,
    slidesPerView: 1,
    navigation: {
      nextEl: ".custom-next",
      prevEl: ".custom-prev",
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    }
  };

  return (
    <>
     <div>

      {/* Heading */}
      <div data-aos="zoom-in" className="md:text-3xl text-xl text-white font-bold font-ubuntu text-center bg-[#fc700d] py-4">
          Our Alumni
        </div>

        {/* Section */}
         <div className="m-8 relative">

           {/* Swiper div */}
           <Swiper {...settings}>

            {/* First Slide */}
            <SwiperSlide>
            <div className="flex md:flex-row flex-col justify-around gap-4 border-dotted border-3 border-gray-400 p-5">
                {/* Alumni Image */}
               <div className="md:w-1/2 w-full flex justify-center">
                 <img src={alumni_img} alt="alumni_img" className="rounded-full object-cover md:w-72 sm:w-60 w-32 md:h-72 sm:h-60 h-32"/>
               </div>

               {/* Alumni Detail */}
               <div className="md:w-1/2 w-full bg-gray-200 p-4 flex flex-col items-center">
                 <div className="sm:text-2xl text-xl text-[#444444] font-bold font-serif">Alumni name</div>
                 <div className="text-sm text-[#444444] font-medium font-ubuntu py-2">(Professor)</div>
                 <span className="py-4 px-2 text-[#444444]">Alumni name 1 from batch 2015 - 2018 [B.Com] and from Batch 2018-2020 [M.Com]
                   from PBS College Banka is now serving as Professor in the state university in Bihar .</span>
               </div>
            </div>
            </SwiperSlide>

                {/* Second slide */}
                <SwiperSlide>
            <div className="flex md:flex-row flex-col justify-around gap-4 border-dotted border-3 border-gray-400 p-5">
                {/* Alumni Image */}
               <div className="md:w-1/2 w-full flex justify-center">
                 <img src={alumni_img2} alt="alumni_img" className="rounded-full object-cover md:w-72 sm:w-60 w-32 md:h-72 sm:h-60 h-32"/>
               </div>

               {/* Alumni Detail */}
               <div className="md:w-1/2 w-full bg-gray-200 p-4 flex flex-col items-center">
                 <div className="sm:text-2xl text-xl text-[#444444] font-bold font-serif">Alumni name</div>
                 <div className="text-sm text-[#444444] font-medium font-ubuntu py-2">(Professor)</div>
                 <span className="py-4 px-2 text-[#444444]">Alumni name 2 from batch 2015 - 2018 [B.Com] and from Batch 2018-2020 [M.Com]
                   from PBS College Banka is now serving as Professor in the state university in Bihar .</span>
               </div>
            </div>
            </SwiperSlide>

               {/* third slide */}
               <SwiperSlide>
            <div className="flex md:flex-row flex-col justify-around gap-4 border-dotted border-3 border-gray-400 p-5">
                {/* Alumni Image */}
               <div className="md:w-1/2 w-full flex justify-center">
                 <img src={alumni_img3} alt="alumni_img" className="rounded-full object-cover border border-gray-100 md:w-72 sm:w-60 w-32 md:h-72 sm:h-60 h-32"/>
               </div>

               {/* Alumni Detail */}
               <div className="md:w-1/2 w-full bg-gray-200 p-4 flex flex-col items-center">
                 <div className="sm:text-2xl text-xl text-[#444444] font-bold font-serif">Alumni name</div>
                 <div className="text-sm text-[#444444] font-medium font-ubuntu py-2">(First Grade Teacher)</div>
                 <span className="py-4 px-2 text-[#444444]">Alumni name 3 from batch 2015 - 2018 [B.Com] and from Batch 2018-2020 [M.Com]
                   from PBS College Banka is now serving as First Grade Teacher in the State Govt. School in Bihar .</span>
               </div>
               </div>
               </SwiperSlide>

           </Swiper>

           {/* Buttons Prev & Next */}
           <button className="custom-prev absolute top-1/2 left-2 -translate-y-1/2 rounded-full bg-gray-200 text-white px-2 hover:bg-transparent z-10">
              &lt;
           </button>
           <button className="custom-next absolute top-1/2 right-2 -translate-y-1/2 rounded-full bg-gray-200 text-white px-2 hover:bg-transparent z-10">
              &gt;
           </button>
          

         </div>

     </div>
    </>
  );
};

export default AlumniPage;
