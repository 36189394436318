import React from "react";
import "./Calender.css";
import bg_courses from "../assets/courses.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Calender = () => {
  return (
    <>
      <section className="events pt-3 bg-[#f6f6f6]">
        <div>
          {/* Heading */}
          <div>
            <h2 className="font-bold text-2xl font-serif text-[#444444] pb-3">Courses</h2>
          </div>
          {/* Container */}
          <div className="flex flex-row justify-around w-full">
            {/* Image */}
            <div className="w-1/2">
              <img className="w-[90%] h-[90%] mx-auto shadow-[8px_8px_16px_-6px_rgba(0,0,0,0.4)]" src={bg_courses} alt="courses" />
            </div>
            {/* Content */}
            <div className="w-1/2 flex flex-col sm:gap-2 gap-0.5 justify-center bg-[#eaeaea]">
            <div className="flex flex-row sm:gap-1 gap-0 sm:ml-1 ml-0"> 
              <a href="/academics">
              <h3 className="font-bold font-serif text-sm sm:text-lg  text-[#444444]">Programs Offered</h3>
              </a>
              <FontAwesomeIcon
                className="text-[#444444] mt-1 animate-bounce w-6 h-6"
                icon="fa-solid fa-book"
              />
            </div>
              <ul className="list-disc bg-gray-200 font-ubuntu text-[#444444]">
                <li className="font-semibold hover:translate-x-1 hover:text-[#7c86ee] ml-6 duration-300">
                  Science
                  <ol className="font-normal">
                    <li>B.Sc</li>
                    <li>M.Sc</li>
                  </ol>
                </li>
                <li className="font-semibold hover:translate-x-1 hover:text-[#7c86ee] ml-6 duration-300">
                  Arts
                  <ol className="font-normal"> 
                    <li>B.A</li>
                    <li>M.A</li>
                  </ol>
                  </li>
                <li className="font-semibold hover:translate-x-1 hover:text-[#7c86ee] ml-6 duration-300">
                  Commerce
                  <ol className="font-normal">
                    <li>B.Com</li>
                    <li>M.Com</li>
                  </ol></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Calender;
