import samplePdf from "./PDFs/notice1.pdf"
import notice2 from "./PDFs/notice2.pdf"
import notice3 from "./PDFs/Notice3.pdf"

export const DataNotice = [
    { id: 1, title: "Notice regarding (75% ATTENDANCE) Released for 3rd semester 2023-27 Batch.", date: "2025-01-08", pdfUrl: notice3 },
    { id: 2, title: "Notice regarding (APAAR ID) Released for 2nd semester 2023-27 Batch , B.A , B.Com.,B.Sc .", date: "2025-01-05", pdfUrl: notice2 },
    { id: 3, title: "Notice regarding (DigiLocker) Released for 2nd semester 2023-27 Batch , B.A , B.Com.,B.Sc .", date: "2024-12-23", pdfUrl: samplePdf },
    { id: 4, title: "Notice regarding (75% ATTENDANCE) Released for 3rd semester 2023-27 Batch.", date: "2025-01-08", pdfUrl: notice3 },
    { id: 5, title: "Notice regarding (APAAR ID) Released for 2nd semester 2023-27 Batch , B.A , B.Com.,B.Sc .", date: "2025-01-05", pdfUrl: notice2 },
    { id: 6, title: "Notice regarding (DigiLocker) Released for 2nd semester 2023-27 Batch , B.A , B.Com.,B.Sc .", date: "2024-12-23", pdfUrl: samplePdf },
]