import React from 'react'
// import { Link } from "react-router-dom";
import { DataNotice } from './DataNotice'

const AllNotice = () => {
  return (
    <div>
      <div>
        <div className="text-[#444444] text-center text-2xl font-serif font-bold">Notice Board</div>
      </div>
      {/* Container */}
      <div className='flex flex-col bg-[#eff7ff] p-4 mx-16'>
        {/* Lists */}
        <div>
        <ul className='list-disc text-[#444444]'>
        {DataNotice.map((notice)=> (
          <li 
          key={notice.id}
          >
            <p className='mb-0 text-sm'>{notice.date}</p>
            <span className='text-[#36348e] font-serif font-semibold hover:text-[#fc700d]'> 
              <a href={notice.pdfUrl} target="_blank" rel="noopener noreferrer">{notice.title}</a>
            </span>
          </li>
        ))}
      </ul>
        </div>
      </div>
    </div>
  )
}

export default AllNotice