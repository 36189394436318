import React from "react";
import Logo from "../assets/schoollogo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <div className="py-4">
        {/* Upper Cont. */}
        <div className="flex md:flex-row p-6 gap-12">

          {/* LOGO */}
          <div className="hidden md:block">
            <img src={Logo} alt="Footer_Logo" onClick={scrollToTop}/>
          </div>

          {/* Container */}
          <div className="p-2 flex flex-col md:flex-row gap-2 items-center md:items-start justify-around bg-[#ff8128] w-full shadow-[0_0_16px_0_rgba(0,0,0,0.4)]">
            {/* Main Navigation*/}
            <div className="hover:bg-[#FFFEB1] py-1 h-full w-full md:w-1/2">
              <h2 className="font-semibold font-ubuntu underline underline-offset-8 decoration-[#444444] text-[#444444] text-lg">MAIN NAVIGATION</h2>
               <div className="flex flex-col py-2 gap-1 items-center text-sm sm:text-[14px] font-medium">
                <a className="hover:text-black hover:scale-105 duration-300" href="/About">About Us</a>
                <a className="hover:text-black hover:scale-105 duration-300" href="/Curriculam">Curriculam</a>
                <a className="hover:text-black hover:scale-105 duration-300" href="/Gallery">Gallery</a>
                <a className="hover:text-black hover:scale-105 duration-300" href="/Admissions">Admissions</a>
                <a className="hover:text-black hover:scale-105 duration-300" href="/Contact">Contact Us</a>
                <a className="hover:text-black hover:scale-105 duration-300" href="/Infrastructure">Our Alumni</a>
               </div>
            </div>
            {/* Location */}
            <div className="hover:bg-[#FFFEB1] py-1 h-full w-full md:w-1/2">
              <h2 className="font-semibold font-ubuntu underline underline-offset-8 decoration-[#444444] text-[#444444] text-lg">LOCATION</h2>
              <div className="py-2 text-sm font-medium hover:text-black">
               <a 
                href="https://www.google.com/maps/dir//P.+B.+S.+College,+Banka,+Kali+Temple,+67,+Rampur,+Meharpur,+Bihar+813102/@24.860691,86.9131485,21z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39f0ffefe1fefa6d:0xddc04ae60b6937d3!2m2!1d86.9132944!2d24.8606281?authuser=0&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex flex-col items-center"
                >
                 <span><FontAwesomeIcon icon="fa-solid fa-location-dot"/>{" "}Address: D-196/2</span>
                 <span>Rampur, Meharpur,</span>
                 <span>Banka , Bihar</span>
              </a>
              </div>
            </div>
            {/* Get In touch*/}
            <div className="hover:bg-[#FFFEB1] py-1 h-full w-full md:w-1/2">
              <h2 className="font-semibold font-ubuntu underline underline-offset-8 decoration-[#444444] text-[#444444] text-lg">GET IN TOUCH</h2>
               <div className="flex flex-col py-2  items-center gap-2 text-sm font-medium">
                <div className="flex flex-row gap-2 ">
                 <FontAwesomeIcon className="pt-1" icon="fa-solid fa-house" />
                  <span>PBS College Banka</span>
                </div>
                <div className="flex flex-row gap-2">
                  <FontAwesomeIcon className="pt-1" icon="fa-solid fa-envelope" />
                  <span>pbscollegebanka.1959@gmail.com</span>
                </div>
                <div className="flex flex-row gap-2">
                  <FontAwesomeIcon className="pt-1" icon="fa-solid fa-phone" />
                  <span>+ 91-8860464098</span>
                </div>
               </div>
            </div>
          </div>

        </div>

        {/* Lower Cont. */}
        <div>
          <h3 className="text-sm text-[#444444] text-center">
            Copyright © 2025 {" "}
            <a href="/" target="_blank" className="text-[#fc700d] hover:text-[#26d3f6]">
               PBS College Banka (Bihar)
            </a>{" "}
          </h3>
        </div>

      </div>
    </>
  );
};

export default Footer;
