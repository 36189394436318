import React, { useEffect } from "react";
import about_bg from "../assets/about_bg.svg";
import about2 from "../assets/home2.jpg";
import library_bg from "../assets/library_bg.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutUs = () => {

  useEffect(() => {
    AOS.init({
        duration: 1200,
        once: false,
        mirror:true
    });
}, []);

  return (
    <>
      {/* Heading About Section */}
      <div className="w-full h-[250px] md:h-[400px]">
        <div
          className="relative h-[180px] md:h-[260px]"
          style={{ backgroundImage: `url(${library_bg})` }}
        >
          <div className="absolute w-full h-full" data-aos="zoom-in">
            <div className="flex text-white font-bold text-[35px] sm:text-[45px] md:text-[65px] font-sans items-center justify-start pl-8 sm:pl-16 pb-4 bg-[#fc700d] w-[80%] md:w-[60%] h-[70%] md:h-[90%] translate-x-[10%] translate-y-[80%] md:translate-y-[45%]">
              ABOUT PBS
            </div>
          </div>
        </div>
      </div>

      {/* Theory About Section */}
      <div className="w-full mt-8">
        <div
          className="bg-contain w-full "
          style={{ backgroundImage: `url(${about_bg})` }}
        >
          <div className="w-[80%] mx-auto py-4 text-[#292929] text-justify font-sans">
            <p className="text-[10px] md:text-[15px]">
            PBS College, Banka, Bihar, was established under the auspices of
            Sita Devi Memorial Shiksha Sansthan, Bihar.Recognized by U.G.C.
            under 2F & 12B Section. It stands as the only
            constituent unit of Tilka Manjhi Bhagalpur University, Bhagalpur in
            the district of Banka, Bihar. Founded in 1959, PBS College has
            traversed a remarkable journey, becoming a cornerstone of higher
            education in the region. With decades of history and growth, the
            college has consistently maintained its commitment to providing
            quality education and fostering intellectual development. The
            college offers a diverse range of undergraduate and postgraduate
            courses in arts and science, catering to the educational needs of a
            large number of students. Its academic curriculum is designed to
            challenge and inspire, empowering students to excel in their chosen
            fields.
            </p>
            <p className="text-[10px] md:text-[15px] pt-3">
            PBS College boasts a sprawling campus that is equipped with
            modern infrastructure, which includes: Well-equipped classrooms
            designed to foster an interactive learning environment.
            State-of-the-art laboratories for hands-on learning and research. A
            well-stocked library providing a vast array of academic and
            reference materials. Hostel facilities for both boys and girls,
            ensuring a comfortable living environment for outstation students.
            Playground and sports facilities, promoting physical fitness and
            athleticism. In addition to academics, PBS College encourages its
            students to engage in various extracurricular activities that
            contribute to their overall development. These include cultural
            events, sports tournaments, debates and quizzes, and social service
            initiatives, all of which play an essential role in shaping
            well-rounded individuals. Given its location in the interior area of
            Bihar, PBS College plays a pivotal role in uplifting the local
            community through its academic and social contributions. The
            college's influence extends beyond its campus, benefiting the entire
            locality with its educational outreach and engagement. PBS College,
            with its rich legacy, modern facilities, and holistic approach to
            education, continues to be a beacon of learning, preparing students
            for a bright future and making a significant impact on the region.
            </p>
            <p className="text-[10px] md:text-[15px] pt-3">
            Your search for the best CBCS college in Bihar will undoubtedly
            bring you to Pandit Baliram Sharma Mahavidyalaya (PBS College).
            Established in 1959, PBS College has been at the forefront of
            providing quality education and fostering talent across a diverse
            range of disciplines. With over six decades of excellence, our
            institution has grown into a premier center for higher education in
            the region. At PBS College, we pride ourselves on our world-class
            infrastructure that supports an environment of learning and
            innovation. Our experienced and highly qualified faculty members are
            dedicated to delivering a curriculum that is both challenging and
            inspiring. We believe in a student-centered approach, where each
            student’s growth is nurtured not only academically but also
            personally. Our goal is to equip our students with the skills and
            knowledge they need to excel in their chosen careers, ensuring that
            they are not only academically proficient but also well-prepared to
            take on leadership roles in a fast-evolving world. With an
            unwavering commitment to excellence, we continue to shape the future
            of our students and provide them with the tools necessary to thrive
            in the competitive global landscape. Choose PBS College for a
            holistic education that will empower you to achieve your dreams and
            unlock your true potential.
            </p>
            <p className="text-[10px] md:text-[15px] pt-3">
              College idealizes education as a passport to the future, for
              tomorrow belongs to those who prepare for it today. Therefore, the
              institution has an exceptional brigade of alumni representing the
              legacy of knowledge and values gained from their journey as
              college students.
            </p>
          </div>
        </div>
      </div>

      {/* Students count Section */}
      <div className="w-full md:h-[1000px] h-[450px]">
        <div className="bg-[#292929] relative w-full md:h-[50%] h-full md:translate-y-[99%] translate-y-0">
          {/* Image */}
          <div className="absolute w-full h-[110%] -translate-y-3/4 hidden md:block ">
            <img
              src={about2}
              alt="about2"
              data-aos="fade-down"
              className="lg:w-[70%] w- h-full mx-auto shadow-[0px_0px_16px_4px_rgba(0,0,0,0.4)]"
            />
          </div>
          {/* Count */}
          <div className="text-white h-[70%] md:translate-y-[40%] md:pt-0 pt-4 translate-y-0 flex md:flex-row flex-col md:gap-0 gap-8 items-center justify-around">
            <div className="flex flex-col gap-3 items-center">
              <span className="font-bold lg:text-8xl text-6xl">3k+</span>
              <span className="font-bold text-[15px]">STUDENTS</span>
            </div>
            <div className="flex flex-col gap-3 items-center">
              <span className="font-bold lg:text-8xl text-6xl">#1</span>
              <span className="font-bold text-[15px]">
                MOST TRANSFORMATIVE COLLEGE
              </span>
            </div>
            <div className="flex flex-col gap-3 items-center">
              <span className="font-bold lg:text-8xl text-6xl">36+</span>
              <span className="font-bold text-[15px]">YEARS OF HISTORY</span>
            </div>
          </div>
        </div>
      </div>

      {/* Milestones */}
      <div>
        {/* heading */}
        <div className="flex flex-col my-4 gap-2 items-center ">
          <div className="text-lg sm:text-2xl md:text-4xl font-bold text-[#444444]">A Glorious Past to a Bright Future</div>
          <div className="text-sm sm:text-xl md:text-3xl font-bold text-[#444444]">Milestones of College</div>
        </div>
        {/* Table container */}
        <div className="w-full">
          <div className="overflow-x-auto w-[80%] mx-auto">
            <table className="min-w-full text-[#444444] border-x border-y border-[#444444]">
              <thead>
                <tr>
                  <th className="border-b border-[#444444] px-4 py-2 text-[#444444] text-lg font-bold font-sans">
                    History
                  </th>
                  <th className="border-b border-[#444444] px-4 py-2 text-[#444444] text-lg font-bold font-sans">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-[#444444] px-4 py-2">
                    Founded as a small institution with 200 students.
                  </td>
                  <td className="border-b border-[#444444] px-4 py-2">1959</td>
                </tr>
                <tr>
                  <td className="border-b border-[#444444] px-4 py-2">
                    Expanded to include a School of Engineering and Sciences.
                  </td>
                  <td className="border-b border-[#444444] px-4 py-2">2000</td>
                </tr>
                <tr>
                  <td className="border-b border-[#444444] px-4 py-2">
                    Recognized as a National Center of Excellence in Education.
                  </td>
                  <td className="border-b border-[#444444] px-4 py-2">2015</td>
                </tr>
                <tr>
                  <td className="border-b border-[#444444] px-4 py-2">
                    Continues to shape future leaders with over 10,000 students.
                  </td>
                  <td className="border-b border-[#444444] px-4 py-2">Present</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
