import React, { useEffect } from "react";
import about_us_new from "../assets/home2.jpg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import about_bg from "../assets/about_bg.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,
      mirror: true,
    });
  }, []);
  return (
    <>
      <div
        className="flex flex-col px-4 py-2 gap-2 bg-contain"
        style={{ backgroundImage: `url(${about_bg})` }}
      >
        {/* Upper Content */}
        <div>
          <div>
            <h2 className="text-[#444444] font-serif font-bold text-2xl pb-3">
              PBS College Banka - Best college in Bihar
            </h2>
          </div>
          <p
            style={{ textAlign: "justify" }}
            className="font-normal text-sm font-sans pb-4"
          >
            PBS College, Banka, Bihar, was established under the auspices of
            Sita Devi Memorial Shiksha Sansthan, Bihar.Recognized by U.G.C.
            under 2F & 12B Section. It stands as the only
            constituent unit of Tilka Manjhi Bhagalpur University, Bhagalpur in
            the district of Banka, Bihar. Founded in 1959, PBS College has
            traversed a remarkable journey, becoming a cornerstone of higher
            education in the region. With decades of history and growth, the
            college has consistently maintained its commitment to providing
            quality education and fostering intellectual development. The
            college offers a diverse range of undergraduate and postgraduate
            courses in arts and science, catering to the educational needs of a
            large number of students. Its academic curriculum is designed to
            challenge and inspire, empowering students to excel in their chosen
            fields. PBS College boasts a sprawling campus that is equipped with
            modern infrastructure, which includes: Well-equipped classrooms
            designed to foster an interactive learning environment.
            State-of-the-art laboratories for hands-on learning and research. A
            well-stocked library providing a vast array of academic and
            reference materials. Hostel facilities for both boys and girls,
            ensuring a comfortable living environment for outstation students.
            Playground and sports facilities, promoting physical fitness and
            athleticism. In addition to academics, PBS College encourages its
            students to engage in various extracurricular activities that
            contribute to their overall development. These include cultural
            events, sports tournaments, debates and quizzes, and social service
            initiatives, all of which play an essential role in shaping
            well-rounded individuals. Given its location in the interior area of
            Bihar, PBS College plays a pivotal role in uplifting the local
            community through its academic and social contributions. The
            college's influence extends beyond its campus, benefiting the entire
            locality with its educational outreach and engagement. PBS College,
            with its rich legacy, modern facilities, and holistic approach to
            education, continues to be a beacon of learning, preparing students
            for a bright future and making a significant impact on the region.
          </p>
        </div>
        {/* Middle Content */}
        <div className="flex flex-row justify-around bg-[#eff7ff]">
          {/* List */}
          <div className="w-full flex items-center">
            <ul className="flex flex-col gap-4 list-none py-2 w-full">
              <li className="px-2 text-[#444444] font-serif font-semibold">
                <IoIosCheckmarkCircle />
                Sports, Clubs and Hobbies
              </li>
              <hr class="h-px my-0.5 bg-[#444444] border-0"></hr>
              <li className="px-2 text-[#444444] font-serif font-semibold">
                <IoIosCheckmarkCircle />
                Dance and Music
              </li>
              <hr class="h-px my-0.5 bg-[#444444] border-0"></hr>
              <li className="px-2 text-[#444444] font-serif font-semibold">
                <IoIosCheckmarkCircle />
                Trips and Excursions
              </li>
              <hr class="h-px my-0.5 bg-[#444444] border-0"></hr>
              <li className="px-2 text-[#444444] font-serif font-semibold">
                <IoIosCheckmarkCircle />
                Art and Craft Studio
              </li>
            </ul>
          </div>
          {/* Image */}
          <div className="w-full sm:block hidden" data-aos="zoom-in">
            <img
              src={about_us_new}
              alt="slider"
              className="object-cover w-[90%] h-[90%] mt-2 mx-auto shadow-[8px_8px_16px_-6px_rgba(0,0,0,0.4)]"
            />
          </div>
        </div>
        {/*Lower Content */}
        <div>
          <div>
            <h2 className="text-[#444444] font-serif font-bold text-2xl py-2">
              PBS college - Best CBCS College in Bihar
            </h2>
          </div>
          <p
            style={{ textAlign: "justify" }}
            className="font-normal text-sm font-sans pb-4"
          >
            Your search for the best CBCS college in Bihar will undoubtedly
            bring you to Pandit Baliram Sharma Mahavidyalaya (PBS College).
            Established in 1959, PBS College has been at the forefront of
            providing quality education and fostering talent across a diverse
            range of disciplines. With over six decades of excellence, our
            institution has grown into a premier center for higher education in
            the region. At PBS College, we pride ourselves on our world-class
            infrastructure that supports an environment of learning and
            innovation. Our experienced and highly qualified faculty members are
            dedicated to delivering a curriculum that is both challenging and
            inspiring. We believe in a student-centered approach, where each
            student’s growth is nurtured not only academically but also
            personally. Our goal is to equip our students with the skills and
            knowledge they need to excel in their chosen careers, ensuring that
            they are not only academically proficient but also well-prepared to
            take on leadership roles in a fast-evolving world. With an
            unwavering commitment to excellence, we continue to shape the future
            of our students and provide them with the tools necessary to thrive
            in the competitive global landscape. Choose PBS College for a
            holistic education that will empower you to achieve your dreams and
            unlock your true potential.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
