import React from "react";
import AdmissionForm from "../Components/AdmissionForm";

const Admissions = () => {
  return (
    <>
      <AdmissionForm />
    </>
  );
};

export default Admissions;
