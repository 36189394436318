import Dashboard from "../dashboard/Admin/Dashboard";
import TeacherRegistration from "../dashboard/Admin/Registration/TeacherRegistration";
import StudentRegistration from "../dashboard/Admin/Registration/StudentRegistration";
import StudentList from "../dashboard/Admin/AllList/StudentList";
import TeacherList from "../dashboard/Admin/AllList/TeacherList";
import AdmissionDetails from "../dashboard/Admin/AdmissionDetails";
import Schedule from "../dashboard/Admin/Schedule";
import StudentAttendance from "../dashboard/Admin/Attendance/StudentAttendance";
import TeacherAttendance from "../dashboard/Admin/Attendance/TeacherAttendance";
import ExamCalander from "../dashboard/Admin/AllExamLists";
import FeeDashboard from "../dashboard/Admin/AllFeesLists";
import SyllabusManagement from "../dashboard/Admin/Syllabus";
import Library from "../dashboard/Admin/Library";
import CoursesPanel from "../dashboard/Admin/CoursePanel";
import StockManagement from "../dashboard/Admin/Stock/StockManagment";
import AccountsManagement from "../dashboard/Admin/AccountsManagement";
import SpecialClasses from "../dashboard/Admin/SpecialClasses";
import ExtraActivities from "../dashboard/Admin/ExtraActivities";
import StudentReport from "../dashboard/Admin/StudentReport";
import AdminRegistration from "../dashboard/Admin/AdminRegistration/AdminRegistration";
import Sidebar from "../dashboard/Admin/Sidebar";
import AddStudent from "../dashboard/Admin/AddStudent"

const AdminLayout = ({ children }) => (
  <div className="flex">
    <Sidebar />
    <div className="pl-14 w-full">{children}</div>
  </div>
);

export const adminRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  {
    path: "/TeacherRegistration",
    element: (
      <AdminLayout>
        <TeacherRegistration />
      </AdminLayout>
    ),
  },
  {
    path: "/StudentRegistration",
    element: (
      <AdminLayout>
        <StudentRegistration />
      </AdminLayout>
    ),
  },
  {
    path: "/StudentList",
    element: (
      <AdminLayout>
        <StudentList />
      </AdminLayout>
    ),
  },
  {
    path: "/addStudent",
    element: (
      <AdminLayout>
        <AddStudent />
      </AdminLayout>
    ),
  },
  {
    path: "/TeacherList",
    element: (
      <AdminLayout>
        <TeacherList />
      </AdminLayout>
    ),
  },
  {
    path: "/AdmissionDetails",
    element: (
      <AdminLayout>
        <AdmissionDetails />
      </AdminLayout>
    ),
  },
  {
    path: "/AllExamLists",
    element: (
      <AdminLayout>
        <ExamCalander />
      </AdminLayout>
    ),
  },
  {
    path: "/StudentAttendance",
    element: (
      <AdminLayout>
        <StudentAttendance />
      </AdminLayout>
    ),
  },
  {
    path: "/TeacherAttendance",
    element: (
      <AdminLayout>
        <TeacherAttendance />
      </AdminLayout>
    ),
  },
  {
    path: "/AllFeesLists",
    element: (
      <AdminLayout>
        <FeeDashboard />
      </AdminLayout>
    ),
  },
  {
    path: "/syllabus",
    element: (
      <AdminLayout>
        <SyllabusManagement />
      </AdminLayout>
    ),
  },
  {
    path: "/library",
    element: (
      <AdminLayout>
        <Library />
      </AdminLayout>
    ),
  },
  {
    path: "/courses",
    element: (
      <AdminLayout>
        <CoursesPanel />
      </AdminLayout>
    ),
  },
  {
    path: "/stock",
    element: (
      <AdminLayout>
        <StockManagement />
      </AdminLayout>
    ),
  },
  {
    path: "/accounts",
    element: (
      <AdminLayout>
        <AccountsManagement />
      </AdminLayout>
    ),
  },
  {
    path: "/special-classes",
    element: (
      <AdminLayout>
        <SpecialClasses />
      </AdminLayout>
    ),
  },
  {
    path: "/extra-activities",
    element: (
      <AdminLayout>
        <ExtraActivities />
      </AdminLayout>
    ),
  },
  {
    path: "/report",
    element: (
      <AdminLayout>
        <ExtraActivities />
      </AdminLayout>
    ),
  },
  {
    path: "/performance",
    element: (
      <AdminLayout>
        <ExtraActivities />
      </AdminLayout>
    ),
  },
  {
    path: "/student-report",
    element: (
      <AdminLayout>
        <StudentReport />
      </AdminLayout>
    ),
  },
  {
    path: "/admin-registration",
    element: (
      <AdminLayout>
        <AdminRegistration />
      </AdminLayout>
    ),
  },
];
