import React, { useState , useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import library_bg from "../assets/library_bg.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs = () => {

  useEffect(() => {
    AOS.init({
        duration: 1200,
        once: false,
        mirror:true
    });
}, []);

  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here (e.g., API call)
    console.log("Form submitted:", formData);
    setFormData({ name: "", email: "", subject: "", message: "" }); // Reset form after submission
  };

  return (
    <div>
      
       {/* Heading Contact Section */}
      <div className="w-full h-[250px] md:h-[400px]">
        <div
          className="relative h-[180px] md:h-[260px]"
          style={{ backgroundImage: `url(${library_bg})` }}
        >
          <div className="absolute w-full h-full" data-aos="zoom-in">
            <div className="flex text-white font-bold text-[35px] sm:text-[45px] md:text-[65px] font-sans items-center justify-start pl-8 sm:pl-16 pb-4 bg-[#fc700d] w-[80%] md:w-[60%] h-[70%] md:h-[90%] translate-x-[10%] translate-y-[80%] md:translate-y-[45%]">
              Contact Us
            </div>
          </div>
        </div>
      </div>

      {/* Container */}
      <div className="flex md:flex-row flex-col w-full gap-4 bg-[#dae6f2] p-4">

        {/* Get in touch details */}
        <div className="md:w-1/2 w-full bg-[#fffeb1]" data-aos="fade" >
          <div className="text-center font-bold font-serif sm:text-5xl text-3xl text-[#444444] pt-2">Campus Location</div>
          <div className="flex flex-col gap-8 py-8 px-4 text-[#444444] font-ubuntu">
          <div>
             <div className="sm:text-3xl text-xl font-bold pb-2">PBS College Banka</div>
             <div className="ml-4">
               <div>D-196/2 , Rampur , Meharpur , Banka (Bihar)</div>
               <div>Phone: + 91-8860464098</div>
               <div>Email: pbscollegebanka.1959@gmail.com</div>
             </div>
          </div>
          <div>
              <div className="sm:text-3xl text-xl font-bold pb-2">Admission Related Enquiry</div>
              <div className="ml-4">
              <div>Email: admissions@pbscollege.in</div>
              <div>(10:00 AM to 5:00 PM)</div>
              </div>
          </div>
          </div>
        </div>

       {/* Form */}
        <div className="md:w-1/2 w-full">
          <div className="text-2xl font-bold text-[#444444] font-serif text-center">Contact Form</div>
          <form onSubmit={handleSubmit} className="text-[#444444] font-ubuntu">
            <div className="mb-3">
              <label htmlFor="name" className="!font-extralight">
                Name
              </label>
              <input
                type="text"
                className="form-control !border-none bg-[#fafafa]"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="!font-extralight">
                Email
              </label>
              <input
                type="email"
                className="form-control !bg-[#fafafa]"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="subject" className="!font-extralight">
                Subject
              </label>
              <input
                type="text"
                className="form-control !border-none !bg-[#fafafa]"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="!font-extralight"> 
                Message
              </label>
              <textarea
                className="form-control !border-none !bg-[#fafafa]"
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="bg-[#26d3f6] rounded py-2 text-serif text-white font-medium hover:scale-105 duration-300">
              Send Message
            </button>
          </form>
        </div>

      </div>
    </div>
  );
};

export default ContactUs;
