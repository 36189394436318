import React from "react";
import Almuni from "../Components/Almuni";

const Infrastructure = () => {
  return (
    <>
      <Almuni />
    </>
  );
};

export default Infrastructure;
