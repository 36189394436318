import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css'; 
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import slider1 from "../assets/Campus.jpeg";
import slider2 from "../assets/home2.jpg";
import slider3 from "../assets/slider2.png";
import slider4 from "../assets/Seminar_brochure.jpeg";
import slider5 from "../assets/Seminar6.jpeg";
import logo from "../assets/slider_5.jpg"

const Slider = () => {

  const images = [
    { src: slider1 , alt:"Image1" },
    { src: slider2 , alt:"Image2" },
    { src: slider3 , alt:"Image3" },
    { src: slider4 , alt:"Image4" },
    { src: slider5 , alt:"Image5" },
  ]
  const settings = {
    modules: [Navigation, Pagination, Autoplay],
    spaceBetween: 30,
    loop:true,
    speed: 500,
    slidesPerView: 1,
    navigation: {
      nextEl: ".custom-next",
      prevEl: ".custom-prev",
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    }
  };

  return (
    <>
       {/* Container */}
      <div className="flex justify-between py-2 px-2">
      <div
    className="relative z-10 flex w-full cursor-pointer items-center !border !border-dashed !border-[#26d3f6] overflow-hidden p-[1.5px]"
  >
        <div
          className="animate-rotate absolute inset-0 h-full w-full rounded-full bg-[conic-gradient(#26d3f6_20deg,transparent_80deg)]"
        ></div>
        <div className="relative z-20 w-full h-full sm:w-[50%] bg-white">
          <img className="relative z-50 w-[90%] md:w-[70%] mx-auto mt-2 object-fill" src={logo} alt="university logo"/>
          <div className="relative z-50 w-[90%] mt-4">
            <div className="flex flex-col mx-4 gap-2 pt-12 sm:pt-0 w-full items-start justify-start md:justify-center md:items-center">
            <h1 className="font-serif font-bold text-5xl sm:text-3xl md:text-5xl text-[#fc700d]">P.B.S. COLLEGE</h1>
            <h3 className="font-sans font-medium text-sm md:text-md text-[#444444]">[A Constituent Unit of TMBU,Bhagalpur]</h3>
            </div>
          </div>
        </div>
        </div>
        {/* Slider */}
        <div className="relative hidden sm:block w-[50%]">
        <Swiper {...settings} className="m-2">
        {images.map((image, index) => (
        <SwiperSlide key={index} className="flex items-center justify-center">
          <img
          src={image.src}
          alt={image.alt}
          className="w-full object-contain"
          />
        </SwiperSlide>
      ))}
      </Swiper>
      {/* Custom Navigation Buttons */}
      <button className="custom-prev absolute top-1/2 left-4 -translate-y-1/2 rounded-full text-[#26d3f6] px-2 hover:bg-transparent z-10">
        &lt;
      </button>
      <button className="custom-next absolute top-1/2 right-4 -translate-y-1/2 rounded-full text-[#26d3f6] px-2 hover:bg-transparent z-10">
        &gt;
      </button>
        </div>
      </div>

      {/* Admission Open Marquee */}
      <div className="bg-[#26d3f6] mt-2 py-2 relative overflow-hidden">
        <div className="text-lg font-bold text-white whitespace-nowrap animate-marquee">
          Admission Open Now &nbsp;&nbsp;|&nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;|&nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;|&nbsp;&nbsp;Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp; | &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;|
        </div>
      </div>

      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </>
  );
};

export default Slider;
